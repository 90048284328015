/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-shadow */
/* eslint-disable react/no-danger */
import React from 'react';
import { graphql } from 'gatsby';
import scrollTo from 'gatsby-plugin-smoothscroll';
import withRoleRequired from '../../../lib/withRoleRequired';
import Layout from '../../../components/layout';
import SEO from '../../../components/seo';
import { AllMarkdownQueryData } from '../../../types/Global';

interface Props {
  data: AllMarkdownQueryData;
}

const ScenarioDataTemplate: React.FunctionComponent<Props> = ({ data }) => {

  const dataMarkdown = data.allMarkdownRemark.edges.find(
    ({node}) => (node.frontmatter.type === "ScenarioData")
  );
  if (!dataMarkdown) return null;

  const dataNodeFrontmatter = dataMarkdown.node.frontmatter;
  const dataNodeHTML = dataMarkdown.node.html;
  const chapterNodes = data.allMarkdownRemark.edges.filter(
    ({node}) => (node.frontmatter.type === "ScenarioDataChapter")    
  ).sort( (node1, node2) => {
    const chapter1 = node1.node.frontmatter.chapter || 0;
    const chapter2 = node2.node.frontmatter.chapter || 0;
    return (chapter1 < chapter2) ? -1 : 1;
  });
  const { slug, title } = data.scenariosCsv;
  return (
    <Layout
      pageHeader={dataNodeFrontmatter.title}
      breadcrumbs={[
        { label: 'Scenarios', link: '/scenarios' },
        { label: title, link: `/scenarios/${slug}`}
      ]}
      moreInfo="scenarios/data" 
    >
      <SEO title={dataNodeFrontmatter.title} />
      <div className="px-4 py-4 mx-auto md:px-8 md:py-4">
        {dataNodeHTML &&
          <div className="px-4 py-4" dangerouslySetInnerHTML={{ __html: `${dataNodeHTML}` }} />
        }
        <main className="pb-8">
          <div className="mx-auto px-4 sm:px-6 lg:px-8">
            {/* Main 3 column grid */}
            <div className="grid grid-cols-1 gap-4 items-start lg:grid-cols-3 lg:gap-8">
              {/* Left column */}
              <div className="grid grid-cols-1 gap-4 lg:col-span-2 order-last lg:order-first">
                <section aria-labelledby="section-1-title">
                  <h2 className="sr-only" id="section-1-title">
                    Scenario Data
                  </h2>
                  <div className="bg-white mb-16 max-h-screen">
                    {chapterNodes && chapterNodes.map( (chapter) => (
                      <div
                        id={`chapter-${chapter.node.frontmatter.chapter}`}
                        key={chapter.node.frontmatter.chapter}
                        className="bg-dstl-light-gray grid grid-cols-1 md:grid-cols-4 my-4"
                      >
                        <img
                          className="h-64 m-4 col-span-1"
                          alt={`Chapter ${chapter.node.frontmatter.chapter}`}
                          src={chapter.node.frontmatter.image}
                        />
                        <div className="col-span-1 md:col-span-3 px-4 md:m-4" dangerouslySetInnerHTML={{ __html: `${chapter.node.html}` }} />
                      </div>
                    ))}
                    <div>&nbsp;</div>
                  </div>
                </section>
              </div>

              {/* Right column */}
              <div className="grid grid-cols-1 hidden lg:block">
                <section aria-labelledby="section-2-title">
                  <h2 className="sr-only" id="section-2-title">
                    Chapters
                  </h2>
                  <div className="bg-white overflow-hidden border-l-2 fixed">
                    <div className="p-6">
                      {chapterNodes && chapterNodes.map( (chapter) => (
                        <div
                          className="bg-dstl-light-gray hover:bg-dstl-crimson flex items-center my-2 cursor-pointer"
                          key={chapter.node.frontmatter.chapter}
                          onClick={() => scrollTo(`#chapter-${chapter.node.frontmatter.chapter}`)}
                        >
                          <img
                            className="h-12 mx-auto flex-none"
                            alt={`Chapter ${chapter.node.frontmatter.chapter}`}
                            src={chapter.node.frontmatter.image}                            
                          />
                          <div className="h-4 flex-1 font-bold mx-4">{chapter.node.frontmatter.chapterTitle}</div>
                        </div>
                      ))}
                    </div>
                  </div>
                </section>
              </div>

            </div>
          </div>
        </main>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($frontmatter__scenario: String) {
    allMarkdownRemark(
      filter: {frontmatter: { type: { in: ["ScenarioData", "ScenarioDataChapter"] }, scenario: { eq: $frontmatter__scenario }} }
    ) {
      edges {
        node {
          html
          frontmatter {
            slug
            title
            scenario
            type
            image
            chapter
            chapterTitle
          }    
        }
      }
    }
    scenariosCsv(slug: { eq: $frontmatter__scenario }) {
      title
      slug
    }        
  }
`

export default withRoleRequired(ScenarioDataTemplate);
